module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Solvana | Residential solar made Smart, Fast, and Easy","short_name":"Solvana","description":"Solvana makes Solar Energy faster and easier than ever. Select solar panels & other solar equipment through our hassle-free portal and we install for you.","start_url":"/","background_color":"#fff","theme_color":"#e86247","display":"standalone","icon":"src/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"0GORMVshGKM3IfCO2ZQgZXllD3SIG8EI","devKey":"0GORMVshGKM3IfCO2ZQgZXllD3SIG8EI","trackPage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
