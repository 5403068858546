// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buy-solar-online-tsx": () => import("./../src/pages/buy-solar-online.tsx" /* webpackChunkName: "component---src-pages-buy-solar-online-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nj-solar-energy-tsx": () => import("./../src/pages/nj-solar-energy.tsx" /* webpackChunkName: "component---src-pages-nj-solar-energy-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-service-areas-tsx": () => import("./../src/pages/service-areas.tsx" /* webpackChunkName: "component---src-pages-service-areas-tsx" */),
  "component---src-pages-smart-fast-easy-tsx": () => import("./../src/pages/smart-fast-easy.tsx" /* webpackChunkName: "component---src-pages-smart-fast-easy-tsx" */),
  "component---src-pages-solar-panel-installation-florida-tsx": () => import("./../src/pages/solar-panel-installation-florida.tsx" /* webpackChunkName: "component---src-pages-solar-panel-installation-florida-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-texas-solar-panel-installation-tsx": () => import("./../src/pages/texas-solar-panel-installation.tsx" /* webpackChunkName: "component---src-pages-texas-solar-panel-installation-tsx" */)
}

